import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO'
import Layout from '../components/Layout'
import LandingPageHero from '../components/Heroes/LandingPageHero'
import SliceRenderer from '../components/ContentBlocks/SliceRenderer'
import { BottomLineGoogleAdContainer } from '../components/styles/GoogleAdsCommon.styles'
import AdHead from '../components/AdHead'
import AdSlot from '../components/AdSlot'

export const Head = ({ pageContext }) => {
  return (
    <AdHead adsData={pageContext.adsData} targets={pageContext.pageTargets} />
  )
}

const LandingPage = ({ data, pageContext }) => {
  const doc = data.prismicLandingPage.data
  const settingsNode = data.prismicGeneralSiteSettings.data
  if (!doc || !settingsNode) {
    return null
  }

  const pageTitle = doc.page_title?.text ?? ''
  const heroText = doc.hero_text?.text ?? ''
  const twitterImage = doc.twitter_image?.url
    ? doc.twitter_image
    : doc.hero_image?.url
    ? doc.hero_image
    : null
  const facebookImage = doc.facebook_image?.url
    ? doc.facebook_image
    : doc.hero_image?.url
    ? doc.hero_image
    : null
  const seoTitle = doc.meta_title?.text
    ? doc.meta_title.text
    : doc.page_title?.text
    ? doc.page_title.text
    : null
  const seo_embed_code = doc.seo_embed_code?.text ?? null
  const bottomLineAdSlot =
    settingsNode.landing_pages_google_ad_tag?.document?.data?.ad_slot_name
  const uid = data.prismicLandingPage.uid
  const isExcluded =
    settingsNode.excluded_documents.filter(
      item => item.excluded_document?.uid === uid
    ).length > 0

  return (
    <>
      <SEO
        seo={{
          description: doc.meta_description,
          keywords: doc.meta_keywords,
          title: seoTitle,
          twitter_image: twitterImage,
          facebook_image: facebookImage,
          seo_embed_code: seo_embed_code,
          isExcluded: isExcluded,
        }}
      />
      <Layout>
        <LandingPageHero
          pageTitle={pageTitle}
          heroText={heroText}
          heroImage={doc.hero_image}
          heroGraphic={doc.graphic_application}
          uid={uid}
        />
        {doc.body &&
          doc.body.map(block => <SliceRenderer key={block.id} block={block} />)}
        {bottomLineAdSlot && (
          <BottomLineGoogleAdContainer>
            <AdSlot divId={`div-${bottomLineAdSlot}`} />
          </BottomLineGoogleAdContainer>
        )}
      </Layout>
    </>
  )
}

export const query = graphql`
  query LandingPageQuery($id: String) {
    prismicGeneralSiteSettings {
      data {
        excluded_documents {
          excluded_document {
            uid
          }
        }
        landing_pages_google_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
        landing_page_interstitial_ad_tag {
          document {
            ... on PrismicGoogleAdTag {
              id
              data {
                ad_slot_name
              }
            }
          }
        }
      }
    }
    prismicLandingPage(id: { eq: $id }) {
      id
      uid
      data {
        meta_description
        meta_keywords
        meta_title {
          text
        }
        seo_embed_code {
          text
        }
        facebook_image {
          url
        }
        twitter_image {
          url
        }
        hero_image {
          alt
          url
          thumbnails {
            mobile {
              url
            }
          }
        }
        hero_text {
          text
        }
        page_title {
          text
        }
        graphic_application
        body {
          ... on PrismicLandingPageDataBodyAccordionBlock {
            id
            slice_type
            primary {
              section_header {
                text
              }
              bottom_padding_adjustment
            }
            items {
              content {
                richText
              }
              block_header {
                text
              }
            }
          }
          ... on PrismicLandingPageDataBodyAdvancedImageBlock {
            id
            slice_type
            primary {
              layout_option
              text_content {
                richText
              }
              cta_text {
                text
              }
              cta_link {
                uid
                link_type
                id
                type
                url
              }
              header_text {
                text
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyAuthorBlock {
            id
            slice_type
            primary {
              author {
                document {
                  ... on PrismicGuestAuthor {
                    id
                    type
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                  ... on PrismicNpfAuthor {
                    id
                    type
                    uid
                    data {
                      name {
                        text
                      }
                      title {
                        text
                      }
                      bio {
                        richText
                      }
                      author_image {
                        alt
                        url
                      }
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyBasicImageBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              image_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyBodyContent {
            id
            slice_type
            primary {
              body_copy {
                richText
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyCalendarOfEventsBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyCtaBlock {
            id
            slice_type
            primary {
              cta_link_text
              layout_style
              cta_link {
                id
                url
                uid
                type
                link_type
              }
              body_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyCustomForm {
            id
            slice_type
            primary {
              form_title
              form_body {
                richText
              }
              form_embed_code {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyEventBlock {
            id
            slice_type
            primary {
              bottom_padding_adjustment
            }
            items {
              event {
                id
                document {
                  ... on PrismicEvent {
                    data {
                      end_date
                      event_date
                      event_city
                      event_state
                      end_time
                      start_time
                      event_venue
                      event_address_line_1
                      event_address_line_2
                      event_image {
                        alt
                        url
                        thumbnails {
                          mobile {
                            url
                          }
                        }
                      }
                      event_title {
                        text
                      }
                      event_description {
                        text
                      }
                      event_link {
                        id
                        link_type
                        uid
                        url
                        type
                      }
                      event_link_text
                    }
                  }
                }
              }
            }
          }
          ... on PrismicLandingPageDataBodyGoogleAd {
            id
            slice_type
            primary {
              google_ad_tag {
                document {
                  ... on PrismicGoogleAdTag {
                    id
                    data {
                      ad_slot_name
                    }
                  }
                }
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyGridBlock {
            id
            slice_type
            primary {
              layout_type
              bottom_padding_adjustment
            }
            items {
              cta_link_text
              cta_link {
                url
                uid
                type
                link_type
                id
              }
              image {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              summary {
                richText
              }
              title {
                text
              }
            }
          }
          ... on PrismicLandingPageDataBodyIconTextBlock {
            id
            slice_type
            primary {
              header {
                richText
              }
              body_text {
                richText
              }
              bottom_padding_adjustment
            }
            slice_type
            items {
              cta_text
              item_title {
                richText
              }
              item_icon {
                alt
                url
                thumbnails {
                  mobile {
                    url
                  }
                }
              }
              item_body_text {
                richText
              }
              cta_link {
                id
                url
                uid
                type
                link_type
              }
            }
          }
          ... on PrismicLandingPageDataBodySponsorsBlock {
            id
            slice_type
            items {
              sponsor_logo {
                alt
                url
              }
              sponsor_link {
                url
                uid
                link_type
                id
              }
            }
            primary {
              intro_text {
                richText
              }
              header {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyStatisticsBlock {
            id
            slice_type
            primary {
              citation
              statistic_prompt
              header {
                text
              }
              subhead {
                text
              }
              bottom_padding_adjustment
            }
          }
          ... on PrismicLandingPageDataBodyVideoBlock {
            id
            slice_type
            primary {
              caption {
                text
              }
              schema_publish_date
              schema_video_description
              video_duration_hours
              video_duration_minutes
              video_duration_seconds
              transcription_link {
                url
                uid
                type
                link_type
              }
              video_title {
                text
              }
              video_thumbnail {
                url
              }
              video_link {
                embed_url
              }
              bitmovin_stream_id
              bottom_padding_adjustment
            }
          }
        }
      }
    }
  }
`

export default LandingPage
